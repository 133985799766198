.testimonials{
    display: flex;
    gap: 1rem;
    margin-top: 13rem ;
    padding: 0 2rem;
}

.subTitle{
    font-size: 20px;
}
.left-testi{
    display: flex;
    flex: 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: aliceblue;
    width: 55vw;
}

.left-testi>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}

.left-testi>:nth-child(2),
.left-testi>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}

.left-testi>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right-testi{
    flex: 1;
    height: 300px;
    position: relative;
}

.right-testi>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.right-testi>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid var(--orange);
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}

.right-testi>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    background: var(--plancard);
    right: 7rem;
    top: 4rem;
}

.arrows{
    display: flex;
    position: absolute;
    gap: 1rem;
    bottom: 1rem;
    left: 17rem;
}

.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 1050px){
    
    .testimonials{
        flex-direction:column;
        align-items: center;
        height: 700px;
        margin: 2rem 0;
        
    }
    .right-testi>img{
        position: relative;
        width: 17rem;
        height: 20rem;
        object-fit: cover;
        right: -0.5rem;
        top: -37rem;
    }
    .right-testi>:nth-child(1){
        position: relative;
        background-color: transparent;
        right: 0.9rem;
        
        width: 80%;
    }
    .right-testi>:nth-child(2){
        position: relative;
        width: 17rem;
        height: 19rem;
        top: -16rem;
        background: var(--plancard);
        right: -2rem;
    }
    .arrows{
        display: flex;
        position: relative;
        gap: 1rem;
        bottom: 33rem;
        left: 7rem;
    }
    .left-testi{
        width: 95vw;
        padding: auto;
   
    }
    .left-testi>:nth-child(2),
    .left-testi>:nth-child(3){
        width: 100vw;
        text-align: center;
    }
    .subTitle{
        text-align: center;
    }
}

@media screen and (max-width: 1620px) and  (min-width: 1050px){
    .testimonials{
        height: auto;
    }
    .arrows{
        display: flex;
        position: absolute;
        gap: 1rem;
        bottom: -7rem;
        left: 8rem;
    }
}

@media screen and  (max-width: 524px){
    .left-testi>:nth-child(2),
    .left-testi>:nth-child(3){
   
    font-size: 2.2rem;
    }

}


