.footer-container {
    position: relative;
}

.footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    align-items: center;
    height: 18rem;
}

.social-links {
    display: flex;
    gap: 5rem;
}

.social-icon {
    cursor: pointer;
    font-size: 8rem; 
    color:white;
}
.social-icon:hover{
    color: var(--orange) 
}

.logo-f>img {
    width: 18rem;
}

.phone-box,
.mail-box,
.location-box{
    color: aliceblue;
    display: flex;
    padding: 0.8rem 0;     
}
.mail,
.phone,
.location{
    font-size: 1.6rem;
    padding-left: 1.5rem;
}

.mail-box:hover{
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: aliceblue;
    -moz-text-decoration-color: aliceblue;
}
.location-box:hover{
    cursor: pointer;
}


.blur-footer-1{
    bottom: 5rem;
    right: 5%;
    width: 50rem;
    height: 8rem;   
    background: rgba(255, 64, 0, 0.5);
}

.blur-footer-2{
    bottom: 0;
    left: 15%;
    width: 45rem;
    height: 10rem;
    background: rgba(255, 115, 0, 0.5);
}


@media screen and (max-width: 1500px){

    .footer{
        flex-direction: column;
        height: auto;
    }
}