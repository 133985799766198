.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
 
}

.programs-header {
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    text-transform: uppercase;
    font-style: italic;
}



.programs-header span{
    text-align: center;
    
}


.programs-categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    padding: 5rem 0;
}

.category{
    display: flex;
    height: 20rem;
    width: 20rem;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 0.5rem;
    justify-content: space-around;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.category:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}

.category>:nth-child(1){
    width: 2.5rem;
    height: 2.5rem;
    fill: aliceblue;
}

.category>:nth-child(2){
    font-size: 1.6rem;
    font-weight: bold;
}

.category>:nth-child(3){
    font-size: 1.2rem;
    line-height: 25px;
}

.join-now {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.category:hover {
    background: var(--plancard);
    cursor: pointer;
}

.programs-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}

.programs-blur-2{
    width: 32rem;
    height: 23rem;
    top: 8rem;
    right: 0;
}

@media screen and (max-width: 1050px){
    
    .category{
        width: 20rem;
    }
    .programs-categories{
        gap: 2.5rem;
    }
    .programs-header{
        flex-direction: column;
        justify-content: center;
        font-size: 2.2rem;
        gap: 3rem;
    }

   
}

@media screen and (max-width: 1620px) and  (min-width: 1050px){
    
    .programs-header{
       gap: 2rem;
    }
    .programs-header span{
        text-align: center;
        font-size: 2.5rem;
    }
    .programs-categories {
    
        padding-bottom: 0rem;
    }
    .right-reasons{
        
    }
}

@media screen and (max-width: 1050px){
    
    .category{
        width: 16rem;
    }
    .programs-categories {
    
        padding-bottom: 0rem;
    }
}


