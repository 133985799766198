.reasons{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10rem;
}
.subTitle{
    font-size: 20px;
}
.left-reasons {
    flex: 1;
    display: flex;
    height: 500px;
    
}

.left-reasons img{
    width: 100%;
    height: 100%;
}

.grid-box-1{
    width: 31%;
    height: 100%;
    margin-right: 2%;
}

.grid-box-2{
    width: 77%;
    height: 100%;
}
.grid-box-3{
    height: 49%;
    margin-bottom: 2%;
}
.grid-box-4{
    height: 49%;
    display: flex;
}
.grid-box-5{
    width: 61%;
    margin-right: 3%;
}
.grid-box-6{
    width: 38%;
    background-color: rgb(9, 255, 9);
}


.right-reasons {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding-left: 10rem;
    margin-top: 4rem;
}

.right-reasons>span{
    font-weight: bold;
    color: var(--orange);
}

.right-reasons>div{
    color: aliceblue;
    font-size: 3rem;
    font-weight: bold;
    padding: 1rem 0;
}

.details-reasons{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-reasons>div{
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 1rem;
}

.details-reasons>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;
}

.partners>img{
    width: 3.5rem;
    padding: 1rem;
}

@media screen and (max-width: 1500px){

    .reasons{
        flex-direction: column-reverse;
        justify-content: center;
        padding:0 1rem;
    }
    .right-reasons{
        width: 100%;
        padding-left: 0rem;
        align-items: center;
    }
   
}


@media screen and (max-width: 1050px){

    .right-reasons>div{
        font-size: 2.4rem; 
    }
   
}
